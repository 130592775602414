import React, { useState, useEffect, useMemo } from "react";
import {
  FaPhone,
  FaEnvelope,
  FaBars,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaClock,
  FaCog,
  FaBolt,
  FaCheckCircle,
  FaUsers,
  FaSyncAlt,
} from "react-icons/fa";

import logo from "./assets/logo.png";
import Hero1 from "./assets/hero2.jpg";
import Hero2 from "./assets/hero3.jpg";
import NFC from "./assets/Cards.png";
import white from "./assets/white.png";
import ser from "./assets/NFC.webp";

function Home() {
  const [currentImage, setCurrentImage] = useState(Hero1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [textVisible, setTextVisible] = useState(true);

  const texts = [
    "Celebrate the upcoming New Year with our innovative digital menu for an enhanced dining experience.",
    "Discover a new era of dining with Gebeta's interactive menus designed for your modern restaurants.",
  ];

  const images = useMemo(() => [Hero1, Hero2], []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTextVisible(false);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setCurrentImage(images[(currentIndex + 1) % images.length]);
        setTextVisible(true);
      }, 1000);
    }, 5000);

    return () => clearInterval(interval);
  }, [currentIndex, images, texts.length]);

  return (
    <div>
      <header
        id="home"
        className="bg-cover bg-center pt-4 h-[80vh] flex flex-col justify-between relative transition-all duration-1000"
        style={{ backgroundImage: `url(${currentImage})` }}
      >
        {/* Dark shadow overlay with gradient */}
        <div className="absolute inset-0 bg-black opacity-70"></div>

        {/* Logo and Navigation Container */}
        <div className="container mx-auto flex items-center justify-between relative z-10 px-4 sm:px-6 md:px-8">
          {/* Navigation Bar on the left of the Logo */}
          <nav className="hidden sm:flex items-center space-x-4 md:space-x-8 text-white text-sm md:text-lg">
            {["HOME", "ABOUT"].map((item) => (
              <li key={item} className="list-none">
                <a href={`#${item.toLowerCase()}`}>
                  <button className="transition-colors duration-500 hover:text-[rgb(25,130,104)] focus:outline-none">
                    {item}
                  </button>
                </a>
              </li>
            ))}
          </nav>

          {/* Logo Centered */}
          <div className="flex items-center justify-center mx-4 md:mx-16">
            <img src={logo} alt="Logo" className="w-16 md:w-24 rounded-full" />
          </div>

          {/* Navigation Bar on the right of the Logo */}
          <nav className="hidden sm:flex items-center space-x-4 md:space-x-8 text-white text-sm md:text-lg">
            {["SERVICE", "CONTACT"].map((item) => (
              <li key={item} className="list-none">
                <a href={`#${item.toLowerCase()}`}>
                  <button className="transition-colors duration-500 hover:text-[rgb(25,130,104)] focus:outline-none">
                    {item}
                  </button>
                </a>
              </li>
            ))}
          </nav>

          {/* Mobile Menu (only visible on small screens) */}
          <div className="flex sm:hidden">
            <button className="text-white text-2xl focus:outline-none">
              <FaBars />
            </button>
          </div>
        </div>

        {/* Grey Line */}
        <div
          className="w-full h-[1px] bg-gray-300 absolute mt-[80px] sm:mt-[108px]"
          style={{ transform: "scaleY(0.3)" }}
        ></div>

        {/* Hero Section */}
        <section className="flex-1 flex items-center justify-center text-white mb-0 relative z-10">
          <div
            className={`text-center transition-transform duration-1000 ease-in-out ${
              textVisible ? "translate-y-0" : "-translate-y-full"
            }`}
          >
            <p className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-shadows font-normal mx-4 sm:mx-16">
              {texts[currentIndex]}
            </p>
          </div>
        </section>
      </header>

      {/* Background Image Section */}
      <section
        className="h-auto bg-center py-9"
        style={{ backgroundImage: `url(${white})` }} // Change to your desired image
      >
        <div className="container mx-auto flex flex-col md:flex-row items-start justify-between px-4 sm:px-6 md:px-8">
          {/* Left Side: Text */}
          <div className="w-full md:w-1/2">
            <h2 className="text-3xl sm:text-4xl md:text-5xl text-[#198268] font-bree text-left mx-2 sm:mx-12 md:mx-28 mt-4">
              EXPLORE OUR SERVICES <br />
              GET NEW OPPORTUNITY!
            </h2>
            <h2 className="text-3xl sm:text-4xl md:text-5xl text-[#198268] font-dancing text-left mx-2 sm:mx-12 md:mx-28 mt-8">
              HAPPY NEW YEAR 2017
            </h2>
            <p className="text-base sm:text-lg md:text-xl text-[#198268] leading-relaxed font-mono text-left mx-2 sm:mx-12 md:mx-28 mt-6 md:mt-12">
              Elevate your dining experience with our luxurious, interactive
              NFC-enabled digital menus designed for your Deluxe Hotels, Gourmet
              Restaurants, and Classic Cafés.
            </p>
          </div>

          {/* Right Side: Image */}
          <div className="w-full md:w-1/2 flex justify-center mt-8 md:mt-0">
            <img
              src={NFC}
              alt="NFC"
              style={{
                backgroundImage:
                  "linear-gradient(to right, #333, #032B44, #021331)",
              }}
              className="w-5/6 h-64 sm:h-72 md:h-96 rounded-lg shadow-2xl"
            />
          </div>
        </div>
      </section>

      {/* Highlights with Background Image */}
      <div>
        <div
          id="grid-section"
          className="py-24 relative"
          style={{
            backgroundColor: "#1A202C", // Dark background color instead of an image
            boxShadow: "0 0 20px rgba(0, 0, 0, 0.8)",
          }}
        >
          <div className="absolute inset-0"></div>
          <div
            id="about"
            className="max-w-7xl mx-auto px-4 py-0 sm:px-6 lg:px-8"
          >
            <h2 className="text-[#198268] text-2xl sm:text-2xl lg:text-4xl font-bold font-bree text-center mb-12">
              OUR VALUES
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
              <div className="bg-gray-800 bg-opacity-50 p-8 rounded-lg shadow-lg text-center transition-transform transform hover:scale-105">
                <h3 className="text-white text-lg sm:text-lg md:text-xl lg:text-2xl font-bold font-bree">
                  MISSION
                </h3>
                <p className="text-gray-300 mt-4 font-bree">
                  To provide the best dining experience through innovative,
                  interactive, and customer-centered digital menus.
                </p>
              </div>

              <div className="bg-gray-800 bg-opacity-50 p-8 rounded-lg shadow-lg text-center transition-transform transform hover:scale-105">
                <h3 className="text-white text-lg sm:text-lg md:text-xl lg:text-2xl font-bold font-bree">
                  VISION
                </h3>
                <p className="text-gray-300 mt-4 font-bree">
                  To revolutionize the restaurant industry by merging luxury and
                  convenience into digital dining experiences.
                </p>
              </div>

              <div className="bg-gray-800 bg-opacity-50 p-8 rounded-lg shadow-lg text-center transition-transform transform hover:scale-105">
                <h3 className="text-white text-lg sm:text-lg md:text-xl lg:text-2xl font-bold font-bree">
                  OBJECTIVE
                </h3>
                <p className="text-gray-300 mt-4 font-bree">
                  To consistently innovate and expand our digital services,
                  making dining seamless, efficient, and enjoyable for everyone.
                </p>
              </div>
            </div>
          </div>
        </div>{" "}
        <div
          id="service"
          className="py-16 relative font-bree" // Applied "Bree" font to the whole section
          style={{
            backgroundImage: `url(${white})`, // Use the imported 'white' image
            backgroundSize: "cover", // Ensures the image covers the whole section
            backgroundPosition: "center", // Centers the image
            backgroundRepeat: "no-repeat", // Prevents the image from repeating
            boxShadow: "0 0 20px rgba(0, 0, 0, 0.8)",
          }}
        >
          <div className="absolute inset-0"></div>
          <div className="max-w-7xl mx-auto px-4 py-0 sm:px-6 lg:px-8">
            {/* Grid layout with two text sections and centered image */}
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-12 items-center">
              {/* Left side with 'Our NFC Digital Menu Services' */}
              <div className="text-left">
                <h2 className="text-[#198268] text-2xl sm:text-2xl lg:text-4xl font-bold mb-16 uppercase tracking-wide">
                  Our NFC Digital Menu Services
                </h2>

                {/* Benefits Section */}
                <div className="text-gray-800">
                  <ul className="space-y-6 text-md sm:text-md lg:text-lg text-gray-800 font-light">
                    {/* Benefit 1 */}
                    <li className="flex items-center space-x-4">
                      <FaUsers className="text-yellow-400 text-3x1" />
                      <span>የMenu ዋጋ በቀላሉ መቀየር የሚያሰችል፣ </span>
                    </li>
                    {/* Benefit 2 */}
                    <li className="flex items-center space-x-4">
                      <FaCheckCircle className="text-blue-400 text-2xl" />
                      <span>ከCustomer ወደ Kitchen በቀጥታ የሚልክ፣</span>
                    </li>
                    {/* Benefit 3 */}
                    <li className="flex items-center space-x-4">
                      <FaCog className="text-green-400 text-2xl" />
                      <span>የተጠቃሚ Engagement የሚጨምር፣</span>
                    </li>
                    {/* Benefit 4 */}
                    <li className="flex items-center space-x-4">
                      <FaBolt className="text-red-400 text-4x1" />
                      <span>የኦዲት ሰራ የሚያቀላጥፍ፣</span>
                    </li>
                    {/* Benefit 5 */}
                    <li className="flex items-center space-x-4">
                      <FaUsers className="text-purple-400 text-2x1" />
                      <span>የሰራተኛ ወጨ የሚቀንስ፣</span>
                    </li>{" "}
                    <li className="flex items-center space-x-4">
                      <FaSyncAlt className="text-purple-400 text-2x1" />
                      <span> የትእዛዝ ፍጥነት እና ቅልጥፍና የሚጨምር፣ </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Center with rounded full image */}
              <div className="text-center">
                <img
                  src={ser} // Your image source
                  alt="Digital Menu Service"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #333, #032B44, #021331)",
                  }}
                  className="w-64 h-64 lg:w-96 lg:h-96 rounded-full mx-auto shadow-2xl transform hover:scale-105 transition-transform duration-300"
                />
              </div>

              {/* Right side with 'What Our Digital Menu Offers' */}
              <div className="text-right">
                <h3 className="text-[#198268] text-2xl sm:text-2xl lg:text-4xl font-bold mb-16 uppercase tracking-wide">
                  What Our Digital Menu Offers
                </h3>
                <div className="text-gray-800">
                  <ul className="space-y-6 text-md sm:text-md lg:text-lg text-gray-800 font-light">
                    {/* Benefit 1 */}
                    <li className="flex items-center space-x-4">
                      <FaUsers className="text-yellow-400 text-2x1" />
                      <span>Analysis ለመሰራት የሚያሰችል፣</span>
                    </li>
                    {/* Benefit 2 */}
                    <li className="flex items-center space-x-4">
                      <FaCheckCircle className="text-blue-400 text-2xl" />
                      <span>ከስህተት ነጻ የሆነ፣</span>
                    </li>
                    {/* Benefit 3 */}
                    <li className="flex items-center space-x-4">
                      <FaCog className="text-green-400 text-2xl" />
                      <span> የተሟላ ቁጥጥር ያለው፣</span>
                    </li>
                    {/* Benefit 4 */}
                    <li className="flex items-center space-x-4">
                      <FaBolt className="text-red-400 text-2x1" />
                      <span>ለአጠቃቀም ቀላል፣ </span>
                    </li>
                    {/* Benefit 5 */}
                    <li className="flex items-center space-x-4">
                      <FaEnvelope className="text-purple-400 text-2x1" />
                      <span>ከ10 በላይ ቋንቋዎች ያሉት፣</span>
                    </li>{" "}
                    <li className="flex items-center space-x-4">
                      <FaSyncAlt className="text-purple-400 text-2x1" />
                      <span>Menu በቀላሉ የማደረስ አቀም የሚሰጥ፣</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer Section */}
      <div
        id="contact"
        className="bg-gradient-to-br from-[#0D1F2D] to-[#198268] py-12 text-white"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row justify-between items-start space-y-12 lg:space-y-0 lg:space-x-8">
          {/* Left Side (Contact Info and Address) */}
          <div className="flex flex-col lg:flex-row space-y-8 lg:space-y-0 lg:space-x-12">
            {/* Contact Us Section */}
            <div className="glass bg-white bg-opacity-10 backdrop-blur-md p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold text-white mb-4">
                Contact Us
              </h3>
              <div className="space-y-4">
                <div className="flex items-center space-x-2">
                  <FaPhone className="h-6 w-6  text-[#00A758]" />
                  <span className="text-gray-300 text-xl font-bree font-normal">
                    0900296860
                  </span>
                </div>{" "}
                <div className="flex items-center space-x-2">
                  <FaPhone className="h-6 w-6 text-[#00A758]" />
                  <span className="text-gray-300 text-xl font-bree font-normal">
                    0904622676
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  <FaEnvelope className="h-6 w-6 text-blue-600" />
                  <span className="text-gray-300 text-xl font-bree font-normal">
                    sales@gebetas.com
                  </span>
                </div>
              </div>
            </div>{" "}
            {/* Open Hours Section */}
            <div className="glass bg-white bg-opacity-10 backdrop-blur-md p-6 rounded-lg shadow-lg">
              <div className="flex items-start space-x-4">
                <FaClock className="text-2xl text-[#00A758] hover:text-white transition-transform duration-300" />
                <div>
                  <h3 className="text-xl font-semibold text-white">
                    Open Hours
                  </h3>
                  <p className="text-gray-300">Mon - Fri: 9 AM - 5 PM</p>
                  <p className="text-gray-300">Sat: 9 AM - 12 PM</p>
                  <p className="text-gray-300">Sun: Closed</p>
                </div>
              </div>
            </div>
          </div>

          {/* Right Side (Social Media Icons) */}
          <div className="flex flex-col items-center lg:items-end space-y-4">
            <h3 className="text-xl font-semibold text-white">Follow Us</h3>
            <div className="flex space-x-6">
              <button className="text-white hover:text-gray-300 text-3xl transition-transform hover:scale-110 duration-200">
                <FaFacebook />
              </button>
              <button className="text-white hover:text-gray-300 text-3xl transition-transform hover:scale-110 duration-200">
                <FaInstagram />
              </button>
              <button className="text-white hover:text-gray-300 text-3xl transition-transform hover:scale-110 duration-200">
                <FaTwitter />
              </button>
            </div>
          </div>
        </div>
        {/* Motto Section */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center mt-12 rounded-lg bg-gray-800 bg-opacity-50 backdrop-blur-lg py-6 shadow-md">
          <p className="text-2xl font-serif italic text-gray-200">
            "Delivering Excellence, One Step at a Time"
          </p>
        </div>{" "}
        <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8 text-center font-salsa">
          <h3>Powered by Bright Sun.</h3>
          <p className="text-sm sm:text-base">
            © 2024{" "}
            <a
              href="https://www.Gebetas.com"
              className="hover:underline focus:ring-0 focus:outline-none"
              rel="noopener noreferrer" // Ensures security
            >
              Gebeta Inc.
            </a>{" "}
            All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
