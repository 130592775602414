import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home"; // Adjust the path if needed

const App = () => {
  useEffect(() => {
    // Disable right-click and specific keys
    const handleContextMenu = (event) => event.preventDefault();

    const handleKeyDown = (event) => {
      // Prevent default behavior if the Ctrl key is pressed
      if (event.keyCode === 17) {
        // Ctrl key
        event.preventDefault();
      }
      // Prevent other specific key combinations
      if (
        event.keyCode === 123 || // F12
        (event.ctrlKey && event.shiftKey && event.keyCode === 73) || // Ctrl+Shift+I
        (event.ctrlKey && event.keyCode === 85) || // Ctrl+U
        (event.ctrlKey && event.keyCode === 83) || // Ctrl+S
        (event.ctrlKey && event.keyCode === 68) || // Ctrl+D
        (event.ctrlKey && event.keyCode === 80) || // Ctrl+P
        (event.ctrlKey && event.keyCode === 86) || // Ctrl+V
        (event.ctrlKey && event.keyCode === 79) || // Ctrl+O
        (event.ctrlKey && event.keyCode === 90) || // Ctrl+Z
        (event.ctrlKey && event.keyCode === 88) // Ctrl+X
      ) {
        event.preventDefault();
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("keydown", handleKeyDown);

    // Detect DevTools
    const detectDevTools = () => {
      const widthThreshold = window.outerWidth - window.innerWidth > 50; // Adjusted threshold
      const heightThreshold = window.outerHeight - window.innerHeight > 50; // Adjusted threshold
      if (widthThreshold || heightThreshold) {
        alert("DevTools detected!");
        // Redirect to a different page
        window.location.replace("https://google.com");
        // Replace history so that the previous page is not in the history stack
        window.history.replaceState(null, null, "https://google.com");
      }
    };

    // Initial check
    detectDevTools();

    // Check on window resize
    window.addEventListener("resize", detectDevTools);

    // Set an interval for continuous detection
    const interval = setInterval(detectDevTools, 1000);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("resize", detectDevTools);
      clearInterval(interval);
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* Add other routes here as needed */}
      </Routes>
    </Router>
  );
};

export default App;
